import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import detailsView from '../views/DetailsView.vue'
import ListView from '../views/ListView.vue'
import Agreement from "@/views/Agreement.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/details',
    name: 'details',
    component: detailsView
  },
  {
    path: '/list',
    name: 'list',
    component: ListView
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: Agreement
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
