import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {Button, NavBar, Popup, Picker, Notify, Dialog, Toast, Divider, Area, Icon, Swipe, SwipeItem,Checkbox} from 'vant';
import 'vant/lib/index.css';

createApp(App)
    .use(store)
    .use(router)
    .use(Button)
    .use(NavBar)
    .use(Popup)
    .use(Picker)
    .use(Notify)
    .use(Dialog)
    .use(Toast)
    .use(Divider)
    .use(Area)
    .use(Icon)
    .use(Swipe)
    .use(SwipeItem)
    .use(Checkbox)
    .mount('#app')
