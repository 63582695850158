<template>
  <div class="index">
    <van-nav-bar title="协议详情" left-arrow @click-left="back"/>
    <div class="main">
      <div class="detail" v-if="select === 1">
        <div class="title">个人信息收集、使用同意函</div>
        <div class="content">
        <span class="indent">
          本人在贵司办理流量卡等业务，同意贵司合法收集并使用本人客
        户信息，相关个人信息包括但不限于：本人姓名、身份证号码、性别、
        手机号码、短信验证码、通信地址、电子邮箱等。
        </span>
          <br>
          <span class="indent">
          上述数据同意贵司向贵司的合作运营商（移动、联通、电信、广
        电等）提供并用于套餐类业务办理、通用流量包类业务办理、定向流
        量包类业务办理、权益+流量包办理、语音短信等业务。
        </span>
        </div>
      </div>
      <div class="detail" v-if="select === 2">
        <div class="title" style="font-size: 15px;">关于客户个人信息收集、使用及保护的公告</div>
        <div class="content" style="font-size: 14px;">
          <div>尊敬的客户：</div>
          <div>您好!</div>
          <span class="indent">
             根据《中华人民共和国个人信息保护法》、《中华人民共和国数据安
          全法》、《中华人民共和国网络安全法》、《全国人民代表大会常务委员
          会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规
          定》（工业和信息化部令第 24 号）和《电话用户真实身份信息登记规定》
          （工业和信息化部令第 25 号）等国家法律法规的要求，客户在运营商各
          类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理店
          等）办理移动电话卡（含无线上网卡）、固定电话、家庭宽带入网、过户
          以及需要出示客户证件的有关业务时，客户应配合出示有效身份证件原件
          并进行查验、登记。登记信息包括姓名、证件类型、证件号码等。同时，
          为更好地提供服务，可能需要客户提供如联系人、联系电话、通信地址、
          电子邮箱等信息。您在使用套餐类业务办理、通用流量包类业务办理、定
          向流量包类业务办理、权益+流量包办理、语音短信这类业务办理服务时，
          我们可能需要收集和使用您的手机号码、短信验证码，确保成功为您办理
          业务。
        </span>
          <br>
          <span class="indent">
          上述数据会提供给我们的合作运营商，用于为您办理业务。同意本文件
          即视为您已了解并同意运营商的隐私和信息保护政策。
        </span>
          <br>
          <span class="indent">
          客户本人持有效身份证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。
        </span>
          <br>
          <span class="indent">
          为向客户提供优质、个性化的服务，我公司将遵循合法、正当、必要
          的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥
          善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外提
          供客户个人信息：
        </span>
          <br>
          <span class="indent">
          1）事先获得客户的明确授权；
        </span>
          <br>
          <span class="indent">
          2）根据有关的法律法规要求；
        </span>
          <br>
          <span class="indent">
          3）按照相关司法机关和/或政府主管部门的要求；
        </span>
          <br>
          <span class="indent">
          4）为维护社会公众的利益所必需且适当；
        </span>
          <br>
          <span class="indent">
          5）为维护我公司或客户的合法权益所必需且适当。
        </span>
        </div>
      </div>
      <div class="detail" v-if="select === 3">
        <div class="title">办理电话号码开户法律风险及防范提示告知书</div>
        <div class="content">
          <span class="indent">
               为进一步加强打击治理通讯网络新型违法犯罪工作，根
            据《中华人民共和国刑法》、《中华人民共和国网络安全法》、
            《中华人民共和国电信条例》、《关于防范和打击电信网络
            诈骗犯罪的通告》、《工业和信息化部关于进一步防范和打
            击通讯信息诈骗工作的实施意见》、《关于依法清理整治涉
            诈电话卡、物联网卡以及关联互联网账号的通告》、《关于
            依法严厉打击惩戒治理非法买卖电话卡银行卡违法犯罪活
            动的通告》等文件相关规定，现对办理电话号码开户人法律
            风险及防范提示，告知如下：
        </span>
          <br>
          <span class="indent">
               一、办理固定电话、移动电话等开户入网手续，必须持
            本人合法有效身份证件实名登记；不得冒用他人身份证件，
            或者使用伪造、变造的身份证件或单位证件办理入网手续。
        </span>
          <br>
          <span class="indent">
          二、不得将办理的电话用于违法犯罪活动；不得转售、倒卖、出租电话卡。
        </span>
          <br>
          <span class="indent">
          三、未取得电信业务经营许可证的任何组织或者个人，不得从事电信业务经营活动。
        </span>
          <br>
          <span class="indent">
              四、“根据《中华人民共和国反电信网络诈骗法》，电
            信业务经营者对监测识别的涉诈异常电话卡用户应当重新
            进行实名核验。”
        </span>
          <br>
          <br>
          <span class="indent" style="font-weight: bold;">
                请开户申请人严格遵守以上规定，如有违反，通信运营
            企业有权采取关停号码、解除合同、纳入信用管理等处置措
            施。对涉嫌伪造变造身份证件、帮助信息网络犯罪活动、非
            法经营等违法犯罪的，将依法严厉追究当事人法律责任。
        </span>
          <br>
          <br>
          <span class="indent" style="color: red;text-decoration: underline; text-decoration-color: red;">
          开户申请人已清楚明确阅读上述告知内容，将依法合规申请、使用相关电信业务。
        </span>
          <br>
          <span class="indent" style="display: flex; justify-content: space-between; align-items: center; height: 100px;">
          客户签名：      日期（年月日）：
        </span>
        </div>
      </div>
      <div class="detail" v-if="select === 4">
        <div class="title">中国电信用户入网协议（2023线上版）</div>
        <div class="content">
          <span>甲方：</span>
          <br>
          <br>
          <span>乙方：中国电信分公司</span>
          <br>
          <br>
          <span style="font-size: 14px; font-weight: bold;">
            重要提示：
          </span>
          <br>
          <span style="font-size: 14px; font-weight: bold;">
              1. 甲方应当为具有完全民事行为能力的自然人。如甲方为无民事行为能力人或限制民事行为能力人，甲方签署本协议应当经过其法定代理人同意并由法定代理人同时签署本协议。
             <br>
              2. 在签署本协议前，请甲方或其代理人仔细阅读本协议各条款，如有疑问请及时咨询。
             <br>
              3. 乙方尊重并保护用户的个人信息，制定了《中国电信个人信息保护政策》，已向甲方告知有关乙方提供本协议项下服务适用的个人信息处理规则。
             <br>
              4. 如甲方为不满十四周岁的未成年人的，甲方父母或其他监护人须同时签署《中国电信儿童个人信息处理知情同意书（适用于不满十四周岁的未成年用户）》（“知情同意书”）。乙方尊重并保护儿童个人信息，制定了《中国电信儿童个人信息处理规则》，作为知情同意书的附件，向甲方告知有关乙方提供本协议项下服务适用的儿童个人信息处理规则。
             <br>
              5. 甲方或其代理人/监护人签署本协议即视为完全理解并同意接受本协议的全部条款。
             <br>
              6. 甲方所需的业务内容、办理方式以及资费方案请详见乙方的相关业务说明。
          </span>
          <br>
          <br>
          <span class="indent">
               根据《中华人民共和国民法典》《中华人民共和国个人信息保护法》《中华人民共和国数据安全法》《中华人民共和国网络安全法》《中华人民共和国反电信网络诈骗法》《中华人民共和国电信条例》及其他有关法律、法规的规定，甲乙双方在自愿、平等、公平、诚实信用的基础上，就电信服务的相关事宜，达成如下协议：
        </span>
          <br>
          <span class="indent" style="font-weight: bold;">
               一、 用户真实身份信息登记要求
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
                  1. 根据《中华人民共和国反电信网络诈骗法》《电话用户真实身份信息登记规定》（工业和信息化部令第 25 号）及有关登记实施规范要求，甲方办理入网手续时须向乙方出示二代居民身份证、外国人永久居留身份证（仅限外国公民使用）、提供真实身份信息，并通过乙方在线视频实人认证等认证方式完成身份信息核验（具体认证方式以乙方网络渠道页面提示为准）。如甲方申办乙方移动电话卡（含无线上网卡），但未完成在线实人认证程序的，乙方将委托第三方递送电话卡给甲方，并授权第三方现场查验甲方身份信息、收集相关资料，甲方须予以配合。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
               2. 甲方应当保证登记的信息及提供的身份信息登记资料真实、有效、准确、完整，并有义务配合乙方或乙方授权的第三方进行查验。甲方可以通过乙方相应渠道查询已登记的身份信息。如不符合用户真实身份信息登记相关法律法规和规定、或登记信息发生变化的，甲方应当及时到乙方营业网点更正或变更。因甲方登记信息不符合规定或未及时办理更正或变更手续等造成的后果，由甲方自行承担。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
                3. 甲方申请入网时，在中国电信全国范围内登记在同一个个人用户名下的移动电话卡达到 5 张的，乙方根据国家相关规定不再为其开办新的移动电话卡。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
               4. 乙方有权按照《中华人民共和国反电信网络诈骗法》，对监测识别的涉诈异常电话卡用户重新进行真实身份信息核验。对未按规定核验或核验未通过的，乙方有权限制、暂停有关电话卡功能。
          </span>
          <br>
          <br>
          <span class="indent" style="font-weight: bold;">
                二、 甲方的权利和义务
          </span>
          <br>
          <br>
          <span class="indent" style="font-size: 14px;">
                1. 甲方有权监督乙方的电信服务质量，及时提出改善意见和建议；对电信服务持有异议的，可以向乙方进行咨询或投诉。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
                2. 甲方有权自主选择乙方依法提供的各类电信业务，有权自主选择乙方公示的资费方案，有权自主选择乙方提供的各类业务办理、咨询、查询和投诉渠道。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
               3. 甲方有权自主选择取得入网许可的终端、设备，并负责安装、调测和维护（包括建筑内自建管线的维护）。甲方自主选择的终端、设备应当具备符合乙方网络的相应功能，否则可能无法支持所选择的电信服务，甲方将自行承担损失。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
               4. 甲方对乙方提供给甲方使用但所有权归乙方的线路、设备或终端有保管责任，不得转借、出租、出售或赠与他人，也不得用于非乙方提供的业务中。由于甲方原因造成该等线路、设备或终端损坏、丢失的，甲方需承担修复费用或按价赔偿。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
            <span style="font-weight: bold; color: #000;">
                   5. 甲方应当按照约定的交费时间和方式及时、足额交纳电信费用。电信费用计费周期一般为自然月，即每月 1 日 0 时至当月最后一日24 时，双方另有约定的除外。逾期交纳电信费用（欠费）的，甲方须每日按所欠费用3‰的标准支付违约金，并承担相应违约责任。</span>由于通信设备产生费用清单及相关处理会有时延（如国际漫游业务等），可能会发生上一计费周期的部分电信费用计入下一计费周期收取的情况。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
                 6. 甲方可以自愿订购或退订第三方服务提供商通过乙方网络提供的增值电信服务，并同意乙方根据第三方服务提供商的委托代为收取相关费用。如甲方对代收费用有异议，可以向第三方服务提供商或乙方提出，由第三方服务提供商自行解决或由乙方协调第三方服务提供商解决。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
               7. 甲方对交纳的电信费用及代收费用有异议的，应当在乙方计费原始数据保存期限内向乙方提出。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
                 8. 甲方有权自主选择或终止以代扣、银行托收等方式支付电信费用，并自主到银行等机构或具备受理条件的营业网点等渠道办理相应手续。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
               9. 甲方通信自由和通信秘密受法律保护。公安机关、国家安全机关、人民检察院及依据法律法规有权进行调查的其他部门根据相关规定提出调查要求的，乙方将依法配合。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
               10. 甲方不得非法买卖、出租、出借名下的电话卡、物联网卡、电信线路、短信端口等。甲方应当确保电信服务的实际使用人信息与乙方登记信息一致，如需转让给他人使用或实际使用人信息发生变化，甲方须按本协议关于用户真实身份信息登记的约定，办理过户手续或变更实际使用人信息，否则乙方有权采取关停相关服务或功能、解除协议以及有权机构要求的其他措施。由此产生的后果和法律责任，由甲方全部承担。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
                11. 甲方不得利用乙方通信资源、电信服务等制作、复制、发布、传播含有以下内容的信息或从事下列违法违规、违背公序良俗的活动，否则乙方有权暂停或限制向甲方提供电信服务，直至终止电信服务。由此产生的后果，乙方不承担责任：
          </span>
          <br>
          <span style="font-size: 14px;">
            （1） 反对宪法所确定的基本原则，破坏国家宗教政策，宣扬邪教和封建迷信的；
             <br>
            （2） 危害国家安全、荣誉和利益，泄露国家秘密，颠覆国家政权，破坏国家统一的；
             <br>
            （3） 宣扬恐怖主义、极端主义，煽动民族仇恨、民族歧视，破坏民族团结的；
             <br>
            （4） 散布虚假信息、诈骗信息、谣言，扰乱经济秩序和社会秩序，破坏社会稳定的；
             <br>
            （5） 散布涉及实施诈骗，制作或销售违禁物品、管制物品，淫秽、色情、赌博、暴力、凶杀、恐怖或教唆犯罪的；（6） 侮辱或诽谤他人，侵害他人名誉、隐私、知识产权和其他合法权益的；
             <br>
            （7） 发送违法信息、拨打诈骗电话，未经接收方同意或请求、或接收方明确表示拒绝的情形下发送骚扰信息或商业性信息、拨打骚扰话音或商业性话音的；
             <br>
            （8） 其他利用乙方通信资源、电信服务等制作、复制、发布、传播违法犯罪信息或进行违法活动的行为。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
                12. 甲方不得实施或协助他人实施下列危害乙方网络安全和信息安全的行为，否则乙方有权暂停或限制向甲方提供电信服务，直至终止电信服务。由此产生的后果，乙方不承担责任：
          </span>
          <br>
          <span style="font-size: 14px;">
            （1） 侵入或非法控制乙方网络或电信设备，盗接乙方电信线路、非法复制乙方电信码号；
             <br>
            （2） 非法获取、删除、修改乙方网络或电信设备中存储、处理、传输的信息和应用程序；
             <br>
            （3） 利用乙方网络从事窃取或破坏他人信息、损害他人合法权益的活动，制作、传播、使用网络改号软件以及对他人进行骚扰的恶意软件；
             <br>
            （4） 提供从事入侵乙方网络、干扰网络正常功能、传输扩散违法信息、窃取网络数据等危害网络安全活动的工具及制作方法；
             <br>
            （5） 设立用于实施诈骗，传授犯罪方法，制作或销售违禁物品、管制物品等违法犯罪活动的网站、通讯群组的；
             <br>
            （6） 故意制作、复制、传播计算机病毒或以其他方式攻击网络等电信设施；
             <br>
            （7） 使用未取得入网许可或可能影响网络安全、网络质量的终端设备或软件的；
            <br>
            （8） 危害乙方网络安全和信息安全的其他行为。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
            <span style="font-weight: bold; color: #000;">
              13. 甲方同意乙方基于提供电信服务、保障通信服务安全、与甲方沟通联系目的处理并与乙方关联公司共享甲方信息，包括身份信息、服务数据及日志信息等。身份信息包括但不限于通过识别仪、高拍仪、纸质表单、互联网等方式收集的姓名、出生日期、身份证件号码等身份证件登记信息、拍摄的影像、住址、电话号码、电子邮箱等；服务数据及日志信息包括但不限于甲方在使用乙方服务过程中上传和产生的位置信息、终端信息、通话记录、使用记录、订购信息、账单、网络信息等。乙方处理甲方个人信息的规则，见《中国电信个人信息保护政策》。如甲方不同意提供或不同意乙方处理如上的某一或某些信息，甲方将可能无法成为乙方的用户或无法享受乙方提供的某些服务，或无法达到相关服务拟达到的效果。本协议解除或终止后，乙方不再收集甲方新的信息，并将按照相关法律法规规定对本协议存续期间收集的甲方信息进行处理。
            </span>
                其中乙方关联公司是指乙方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的合法继承人。其中“控制”是指直接或间接地拥有影响所提及公司或机构管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。
          </span>
          <br>
          <span class="indent" style="font-size: 14px; font-weight: bold; color: #000;">
                14. 未经甲方授权同意，乙方不向第三方提供甲方信息。如甲方授权与乙方有合作关系的银行等金融机构、依法设立的征信机构、数据服务机构、信息查询服务机构、信息验证服务机构、互联网企业等第三方向乙方收集、核验甲方信息，甲方同意乙方有权向该等第三方提供相关的甲方信息和核验信息。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
                15. 为保护公共利益、优化电信服务质量、保障甲方电信服务知情权、及时警示通讯信息诈骗、创新电信服务体验，甲方同意乙方使用甲方信息通过营业网点、网站、短信、互联网媒体、电子邮件、语音外呼等方式，向甲方告知社会公益、电信服务、业务使用、诈骗风险警示等信息。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
                16. 甲方有权自主选择携号转网服务，即在保持移动电话号码不变的情况下变更签约的电信运营企业。甲方办理携号转网业务应当符合工业和信息化部携号转网有关文件的规定条件和程序。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
                17. 办理携号转网业务过程中，甲方同意乙方将必要的甲方身份信息提供给工业和信息化部集中业务管理系统（CSMS 系统），用于比对携入方和携出方登记的身份信息是否一致。
          </span>
          <br>
          <br>
          <span class="indent" style="font-weight: bold;">
            三、 乙方的权利和义务
          </span>
          <br>
          <br>
          <span class="indent" style="font-size: 14px;">
            1. 乙方在其通信网络与设施覆盖范围内，向甲方提供电信服务；其中，乙方在签订有自动漫游协议的国家和地区的电信运营商的网络覆盖范围内为甲方提供国际移动通信漫游服务及港澳台地区移动通信漫游服务。乙方提供的电信服务应符合国家规定的电信服务质量标准。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
            2. 乙方免费向甲方提供中华人民共和国境内（为本协议目的，仅限大陆地区，不含港澳台）火警、匪警、医疗急救、交通事故报警等紧急呼叫服务。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
            3. 乙方根据生产经营成本、电信市场供求状况等因素，合理确定并公示电信业务资费方案。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
            4. 乙方可以通过营业网点、网站、电话、广播、短信、彩信、电子邮件、电视、公开张贴、信函、报纸、互联网媒体等一种或数种方式，公布、通知或告知业务种类和服务项目、范围、时限、资费方案以及其他电信服务内容。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
           5. 乙方可以通过电话热线、营业网点、网上营业厅、掌上营业厅或短信等多种渠道为甲方提供业务办理、咨询、查询、障碍申告、投诉受理等服务。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
           6. 乙方负责网络接入保障、提供相关设备的安装、调测和维护服务，具体服务内容及费用等详见相关业务说明。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
           7. 甲方向乙方申告除网络覆盖和终端设备故障外的电信服务障碍，乙方应当自接到申告之日起，城镇 48 小时、农村 72 小时内修复或调通。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
           8. 乙方因检修线路、设备搬迁、工程施工、网络建设等原因可能影响正常电信服务的，应当提前 72 小时向社会公告，通告方式包括但不限于网站、广播、短信、电视、公开张贴、报纸、互联网媒体等。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
           9. 乙方向甲方提供免费的电子账单服务，并有义务对账单进行解释。乙方对甲方计费原始数据保留期限为 5 个月（系统产生用户话单当月起后5个月，不含当月），法律另有规定除外。如数据保留期限内甲方对电信费用提出异议，乙方应当保存相应计费原始数据至异议解决。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
           10. 乙方可以根据对甲方实际情况的综合评估，与甲方约定一定时间内的电信消费透支额度（“信用额度”）并可以进行周期性调整。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
           11. 乙方有权根据具体业务类型按预付方式或后付方式向甲方收取电信费用：
             <br>
            <span class="indent">
            （1） 预付方式下，甲方需预存费用。当账户余额加上信用额度（如有）不足以支付甲方拟使用的电信业务费用时，甲方应当及时充值或交费，否则乙方可暂停提供电信服务（“欠费停机”）。
            </span>
             <br>
            <span class="indent">
              （2） 后付方式下，甲方累计未交的费用到达信用额度、或到达交费期时，甲方应当及时充值或交费，否则乙方可暂停提供电信服务。其中，甲方当月累计出账费用的交费期为次月整月，另有约定的除外。
            </span>
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
           12. 甲方自欠费停机之日起 60 日内结清费用并申请复机的，乙方应当在收到甲方费用时起 24 小时内为甲方恢复暂停的电信服务（“复机”）。
          </span>
          <br>
          <span class="indent" style="font-size: 14px; font-weight: bold; color: #000;">
           13. 如甲方自欠费停机之日起满 60 日仍未结清欠费和违约金，乙方有权自欠费停机第 61 日起终止提供电信服务，收回相应号码（“销户”）、解除本协议，以及通过信函、电话、诉讼或委托第三方等形式追缴欠费和违约金，并可以依法向征信机构提供甲方失信信息。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
           14. 对经设区的市级以上公安机关认定的非法买卖、出租、出借电话卡、物联网卡、电信线路、短信端口以及假冒他人身份或者虚构代理关系开立上述卡或业务的单位、个人，以及因从事电信网络诈骗活动或者关联犯罪受过刑事处罚的人员，乙方有权按照国家有关规定采取限制其有关卡、账户、账号等功能和停止非柜面业务、暂停新业务、限制入网等措施。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
           15. 乙方严格按照《中华人民共和国个人信息保护法》《中华人民共和国数据安全法》《中华人民共和国反电信网络诈骗法》《电信和互联网用户个人信息保护规定》（工业和信息化部令第 24 号）等法律法规的相关要求，在业务活动（包括但不限于业务受理系统登记、纸质返档，通过网络接收、读取并记录、身份验证、客户服务、安全防范、诈骗监测、邀请甲方参与有关乙方产品和服务的调查）中处理甲方信息。为提供更为精准优质的服务，乙方可以依法对包含甲方在内的整体用户数据进行分析并加以利用。乙方承诺对甲方信息依法负有保密义务，不得泄露、篡改或毁损，不得非法出售或非法向他人提供，并采取符合业界标准、合理可行的安全防护措施保护甲方的个人信息，防止甲方的个人信息遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
           16. 为遵守相关法律法规或者行政机关、监管机构等有权部门依法提出的要求以及基于诉讼/仲裁需要，乙方有权披露甲方的信息。
          </span>
          <br>
          <br>
          <span class="indent" style="font-weight: bold;">
            四、 特殊情况的责任承担
          </span>
          <br>
          <br>
          <span class="indent" style="font-size: 14px; font-weight: bold; color: #000;">
           1. 甲方入网后，应当妥善保管原始票据（如押金票据等），发生遗失的，由甲方自行承担责任。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
           2. 甲方密码是甲方办理业务的重要凭证。甲方入网后应当立即设置甲方密码，并妥善保管。为方便办理业务，乙方也可以根据甲方申请以短信等方式提供随机密码，该密码可以作为办理业务的临时凭证。
          </span>
          <br>
          <span class="indent" style="font-size: 14px; font-weight: bold; color: #000;">
           3. 凡使用甲方密码或随机密码定制、变更或取消业务的行为，均被视为甲方或甲方授权的行为。因甲方对密码保管不善造成的损失由甲方自行承担，但乙方有义务协助甲方或公安机关调查相关情况。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
           4. 甲方应当合理管理电信费用支出，出现异常的高额电信费用时（指超过甲方此前 3 个月平均电信费用 5 倍以上的费用），乙方一经发现，应当尽可能迅速告知甲方，如告知后未得到甲方确认的，乙方有权暂停本协议约定的部分或全部服务。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
           5. 在甲方欠费停机的情况下，乙方有权拒绝为甲方办理除交费、电信费用查询外的其他电信业务。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
           6. 一方违约给对方造成直接损失的，应当依法承担赔偿责任。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
           7. 因不可抗力而使本协议部分或全部不能履行的，部分或全部免除责任，但法律另有规定的除外。不可抗力指不能预见、不能避免并不能克服的客观情况。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
            <span style="font-weight: bold; color: #000;">
              8. 甲方使用本协议项下电信服务过程中涉及的第三方服务（如第三方支付、银行转账、与第三方应用绑定等），由第三方承担责任。
            </span>
           对于甲方提出的异议，乙方可以协调第三方解决。
          </span>
          <br>
          <span class="indent" style="font-size: 14px; font-weight: bold; color: #000;">
           9. 乙方可能重新启用销户的移动电话号码。号码被销户前原使用该号码的用户（“原机主”）如将该号码用作第三方联系电话或者绑定第三方业务，且原机主未在销户前予以变更或解除绑定，该号码可能会收到第三方发给原机主的通知，或者因第三方的限制无法在解除绑定前完成新用户认证绑定。甲方需自行办理解除绑定等手续，乙方在合理限度内提供配合。
          </span>
          <br>
          <br>
          <span class="indent" style="font-weight: bold;">
            五、 协议的生效、变更、终止和解除
          </span>
          <br>
          <br>
          <span class="indent" style="font-size: 14px; font-weight: bold; color: #000;">
           1. 除本协议另有约定外，本协议自甲方以点击确认或其他方式表示同意本协议时成立，自甲方按本协议第一条第 1 款完成身份信息线上核验或现场查验时生效。如甲方办理号码携入的，本协议自甲方以点击确认或其他方式表示同意本协议时成立，于甲方按本协议第一条第1 款完成身份信息线上核验或现场查验、乙方收到携号转网成功生效结果告知之时起生效。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
           2. 甲方如终止使用电信服务，应当结清电信费用及违约金（如有），并至乙方营业网点申请销户或号码携出，销户或号码携出后本协议终止。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
           3. 未经双方同意并办理有关手续，甲、乙方不得将依据本协议办理的业务全部或部分转让给第三方。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
            4. 甲方申请销户或号码携出时账户仍有余额的，乙方根据实际情况将余额一次性转入甲方指定的乙方运营的其他有效号码的账户中，或以中国电信充值卡等方式退还；
            <span style="font-weight: bold; color: #000;">
              甲方通过参与赠送、优惠、兑换等营销活动获取的余额，不予退还，如按营销活动的业务规则另有约定的，按其业务规则处理。
            </span>
          </span>
          <br>
          <span class="indent" style="font-size: 14px; font-weight: bold; color: #000;">
          5. 因乙方电信业务经营许可证的业务范围、业务种类发生改变，或因乙方对移动通信网络进行整体换代升级、技术进步、国家政策变动等原因，乙方无法向甲方继续提供原有业务或需对原有业务的服务方式、服务功能、操作方法、业务号码等进行调整时，乙方有权变更或终止本协议，但应当提前通知或公告，提出解决方案，做好客户服务。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
           6. 有下列情形之一的，乙方有权单方终止或解除本协议：
            <br>
            <span class="indent">
              （1） 甲方未按照用户真实身份信息登记要求，提供的登记资料不真实、不准确、不完整、无效，或经乙方通知后未在合理期限内配合提供的；
            </span>
             <br>
            <span class="indent">
              （2） 甲方收到电话卡后 20 天内未激活使用的；
            </span>
            <br>
            <span class="indent">
              （3） 甲方存在本协议第二条第 11 款、第 12 款所列任一行为，或由此引起用户投诉或举报的
            </span>
            <br>
            <span class="indent">
              （4） 甲方未办理相关手续，自行改变电信服务使用性质、用途，或出租、二次转售、倒卖电话卡，或自行转让协议的；
            </span>
            <br>
            <span class="indent">
              （5） 甲方利用乙方服务从事相应活动，依法应当取得而未取得或丧失相应许可或备案
            </span>
            <br>
            <span class="indent">
              （6） 甲方自欠费停机之日起 60 日内仍未结清所有欠费、违约金、赔偿金等费用的；
            </span>
            <br>
            <span class="indent">
              （7） 有权机关要求乙方停止对甲方提供电信服务的；
            </span>
            <br>
            <span class="indent">
              （8） 甲方原因造成本协议无法履行的；
            </span>
                        <br>
            <span class="indent">
              （9） 法律、法规、规章及规范性文件规定的其他情形。
            </span>
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
           7. 本协议终止或解除后，乙方继续保留向甲方追缴所欠费用的权利，并有权收回甲方原使用的业务号码（销户的号码）。乙方对销户的业务号码冻结 90 日后将重新分配给其他用户使用。
          </span>
          <br>
          <span class="indent" style="font-size: 14px; font-weight: bold; color: #000;">
           8. 甲方改号、销户或过户时应当主动注销或解绑在该号码上的注册或绑定的第三方应用（如微信、支付宝）、银行卡（如银行验证号码）等信息、以及第三方软件，自行解除银行托收、代扣等第三方协议，自行卸载甲方加载的第三方软件，避免造成甲方信息泄露；否则由此造成甲方或其他人损失由甲方承担。
          </span>
          <br>
          <span class="indent" style="font-size: 14px; font-weight: bold; color: #000;">
           9. 为使甲方便捷使用乙方或其关联公司提供的产品或服务、保障信息安全，如甲方通过其在乙方办理的移动电话号码使用（包括但不限于登录、真实身份验证等）乙方或乙方关联公司运营的各类互联网应用（统称“中国电信互联网应用”）的，中国电信互联网应用将默认该移动电话号码作为甲方的中国电信互联网应用账号（统称“天翼账号”）。当甲方对该移动电话号码进行改号、销户或过户时，中国电信互联网应用将自动注销甲方的上述天翼账号，并同步删除该天翼账号中的全部信息。甲方应当在改号、销户或过户前另行备份上述天翼账号中的信息。如甲方未备份造成信息丢失的，由甲方自行承担，乙方或乙方关联公司不承担责任且无义务为甲方进行信息恢复。中国电信互联网应用包括但不限于 189 邮箱、天翼云盘。
          </span>
          <br>
          <br>
          <span class="indent" style="font-weight: bold;">
            六、 其他约定
          </span>
          <br>
          <br>
          <span class="indent" style="font-size: 14px;">
          1. 所有因本协议引起的或与本协议有关的争议，可以通过协商、请求消费者协会或依法成立的其他调解组织调解（限于甲方为消费者）、向有关行政部门投诉，或者向乙方所在地的人民法院起诉等方式解决。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
          2. 如果本协议的任何条款在任何时候变成不合法、无效或不可强制执行但不从根本上影响本协议的效力时，本协议的其他条款不受影响。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
          3. 甲方办理各类业务所签署的业务登记单为本协议的一部分，业务登记单与本协议条款冲突的部分以业务登记单为准。乙方以公告等书面形式公开做出的服务承诺为本协议的补充，该等服务承诺与本协议冲突的部分以服务承诺为准。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
          4. 有关乙方提供本协议项下服务适用的个人信息处理规则，见《中国电信个人信息保护政策》。《中国电信个人信息保护政策》与本协议条款冲突的部分以《中国电信个人信息保护政策》为准。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
          5. 如甲方为不满十四周岁的未成年人的，甲方父母或其他监护人签订本协议时须同时签署《中国电信儿童个人信息处理知情同意书（适用于不满十四周岁的未成年用户）》（“知情同意书”）。甲方同意乙方按照知情同意书及其附件《中国电信儿童个人信息处理规则》处理甲方的个人信息。知情同意书及其附件《中国电信儿童个人信息处理规则》为本协议的一部分，知情同意书及其附件《中国电信儿童个人信息处理规则》与本协议条款冲突的部分以知情同意书为准。
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
          6. 本协议一式两份，双方各保留一份，具有同等法律效力。甲乙双方以电子方式签署本协议的，甲方可以通过乙方提供的途径或指引查询、下载本协议。
          </span>
          <br>
          <br>
          <span class="indent" style="font-size: 14px;">
          甲方：（签名或单位盖章）
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
         地址：
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
          联系方式：
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
          签署日期： 年 月 日
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
          甲方法定代理人：（签名或单位盖章）
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
          地址：
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
          联系方式：
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
          签署日期： 年 月 日
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
          乙方：（名称并盖章）
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
          地址：（乙方单位注册地址）
          </span>
          <br>
          <span class="indent" style="font-size: 14px;">
          签署日期： 年 月 日
          </span>
        </div>
        <div style="height: 60px"/>
      </div>
    </div>
    <div class="footer">
    </div>
  </div>
</template>

<script>
export default {
  name: "user-agreement",
  data() {
    return {
      select: null,
    }
  },
  created(){
    let id = this.$route.query.select;
    this.select = parseInt(id);
  },
  methods: {
    back(){
      this.$router.back();
    }
  }
};

</script>

<style lang="less" scoped>
::-webkit-scrollbar {
  display: none;
}
.index{
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  height: 100vh;

  .main{
    flex: 1 0 0;
    overflow: auto;
    .detail {
      height: 100%;
      padding: 40px;
      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bold;
      }
      .content {
        line-height: 30px;
        margin-top: 40px;
        .indent {
          margin-left: 25px;
        }
      }
    }
  }
  .footer {
    flex: 0 0 auto;
  }
}
</style>
