<template>
  <div class="home">
    <!-- <van-nav-bar title="首页"/>
    <div class="company-info">
      <div><strong>名称：</strong>{{ companyInfo.name }}</div>
      <div><strong>统一信用代码：</strong>{{ companyInfo.creditCode }}</div>
      <div><strong>经营范围：</strong>{{ companyInfo.businessNature }}</div>
      <item-card :item-list="products"/>
    </div> -->
    <div class="search" @click="search">
      <div class="icon"><van-icon name="search" size="20" /></div>
      <input type="text" placeholder="搜索想要的商品">
      <div class="button">搜索</div>
    </div>
    <div class="img">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item,index) in bannerList" :key="index" style="height: 120px" >
          <img style="width: 100%; height: 100%; object-fit: fill" :src="IMAGE_BASE_URL + item.image" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="project">
      <div class="title">综合</div>
      <item-card :item-list="products"/>
    </div>
    <div class="footer">
      <div class="explain">恨据工信部2018年9月1日实名新规要求，您下单时需填写机主本人姓名及身份证号码，套餐电话卡到手后需激活，上传身份证正面、反面、及本人正面免冠照片。线上登记的机主姓名需与实名开户的机主姓名与身份证一致，开户和活会将信息在国政通联网对比，通过后才能激活使用。</div>
      <div class="progress">
        <div class="pro-item">
          <div class="img-icon">
            <img src="../assets/icon1.png" alt="">
          </div>
          <div class="tag">填写资料</div>
        </div>
        <div class="arrow">
          <img src="../assets/arrow.png" alt="">
        </div>
        <div class="pro-item">
          <div class="img-icon">
            <img src="../assets/icon2.png" alt="">
          </div>
          <div class="tag">免费配送</div>
        </div>
        <div class="arrow">
          <img src="../assets/arrow.png" alt="">
        </div>
        <div class="pro-item">
          <div class="img-icon">
            <img src="../assets/icon3.png" alt="">
          </div>
          <div class="tag">当面激活</div>
        </div>
        <div class="arrow">
          <img src="../assets/arrow.png" alt="">
        </div>
        <div class="pro-item">
          <div class="img-icon">
            <img src="../assets/icon4.png" alt="">
          </div>
          <div class="tag">安全使用</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useRouter} from 'vue-router'
import ItemCard from "@/components/ItemCard.vue";
import {listProduct} from "@/api/product";
import {listBanner} from "@/api/banner";
import {IMAGE_BASE_URL} from "@/const/base";

// const companyInfo = ref({
//   name: '衢州旭煌信息技术有限公司',
//   creditCode: '91330802MADN1W0120',
//   businessNature: '一般项目:数据处理服务;工业互联网数据服务;互联网安全服务;互联网数据服务;数字技术服务;网络设备销售;云计算设备销售;商务代理代办服务;基于云平台的业务外包服务;软件开发;人工智能硬件销售;人工智能行业应用系统集成服务;人工智能公共数经营范围据平台;计算机软硬件及辅助设备零售;通讯设备销售;通信设备销售;家用电器销售;办公设备销售;互联网销售(除销售需要许可的商品)(除依法须经批准的项目外，凭营业执照依法自主开展经营活动)。许可项目:第二类增值电信业务(依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以审批结果为准)。'
// })

const router = useRouter();

function search(){
  router.push('/list')
}

const products = ref([])
const bannerList = ref([])

const getList = () => {
  listProduct().then(res => {
    products.value = res.data;
  })
}

const getBannerList = () => {
  listBanner().then(res => {
    bannerList.value = res.data;
  })
}

onMounted(() => {
  getList();
  getBannerList();
})

</script>
<style lang="less" scoped>
.home {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f2f4f7;

  .company-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    box-sizing: border-box;
    align-items: flex-start;
    word-break: break-all; /* 允许在任意位置换行 */
    white-space: normal; /* 允许空格和换行符生效 */
    text-align: left;

  }
  .search{
    height: 32px;
    border-radius: 15px;
    border: 1px #2a82e4 solid;
    display: flex;
    align-items: center;
    margin: 15px;
    background-color: #fff;
    box-sizing: border-box;
    .icon{
      padding: 2px 10px 0;
      height: 32px;
      line-height: 32px;
    }
    input{
      flex: 1 0 0;
      border: none;
    }
    .button{
      flex: 0 0 auto;
      height: 32px;
      width: 64px;
      line-height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background: rgba(42, 130, 228, 1);
      border-radius: 15px;
      font-size: 15px;
    }
  }
  .img{
    height: 120px;
    padding: 0 15px 20px;

  }
  .project{
    min-height: 260px;
    padding: 0 15px;
    .title{
      font-size: 16px;
      color: rgba(51, 51, 51, 1);
      text-align: left;
      padding: 0 0 5px;
      &::after{
        content: '';
        display: block;
        background: rgba(42, 130, 228, 1);
        width: 20px;
        height: 2px;
        margin: 2px 3px 0;
      }
    }
  }
  .footer{
    padding: 18px 20px 24px;
    color: #fff;
    background: linear-gradient(106.65deg, rgba(70, 131, 255, 1) 0%, rgba(24, 100, 254, 1) 100%);

    .explain{
      font-size: 10px;
      line-height: 15px;
    }
    .progress{
      padding: 30px 15px 0;
      display: flex;
      justify-content: space-between;
      .pro-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        .img-icon{
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: 2px solid rgba(255, 255, 255, 1);
          box-sizing: border-box;
        }
        .tag{
          font-size: 10px;
          padding: 8px 0 0;
        }
      }
      .arrow{
        padding: 15px 0 0;
      }
    }
  }
}
.my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 120px;
    text-align: center;
    background-color: #39a9ed;
}
</style>
