<template>
  <div class="item-list">
    <div class="item-box" v-for="(item,index) in itemList" :key="index" @click="toDetails(item.productId)">
      <div class="item-top">
        <img :src="IMAGE_BASE_URL + item.image">
        <div class="info">
          <div class="title">{{ item.title }}</div>
          <div class="desc">{{ item.subtitle }}</div>
        </div>
      </div>
      <!-- <van-divider style="width: 100%"/> -->
      <div class="item-bottom">
        <!-- <div class="btn">推广链接</div>
        <div class="btn">生成海报</div> -->
        <div class="btn">免费领取</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {defineProps} from 'vue'
import {useRouter} from 'vue-router'
import {IMAGE_BASE_URL} from "@/const/base";

const router = useRouter();
defineProps({
  itemList: {
    type: Array,
    default: null
  }
})

function toDetails(productId) {
  router.push({
    path: '/details',
    query: {productId}
  })
}
</script>
<style scoped lang="less">
.item-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 5px 0;
  box-sizing: border-box;
  

  .item-box {
    width: 48%;
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    margin: 8px 0;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: white;

    .item-top {
      width: 100%;
      display: flex;
      flex-direction: column;

      img {
        width: 100%;
      }

      .info {
        padding: 5px 0 0;
        .title {
          font-size: 15px;
          color: #333;
        }

        .desc {
          font-size: 12px;
          color: #999;
          padding: 5px 0;
        }
      }
    }

    .item-bottom {
      display: flex;
      padding: 10px 0;
      .btn {
        width: 70px;
        height: 25px;
        line-height: 25px;
        font-size: 10px;
        color: #fff;
        background-color: #2a82e4;
        text-align: center;
        border-radius: 5px;
        margin-right: 10px;
      }
    }
  }
}
</style>
