import request from "@/utils/request";

export function listProduct() {
    return request({
        url: '/app/product-list',
        method: 'get',
    })
}

export function selectProductList(name) {
    return request({
        url: '/app/selectList?searchName='+name,
        method: 'get',
    })
}

export function productDetail(productId) {
    return request({
        url: `/app/product-detail/${productId}`,
        method: 'get'
    })
}

export function submitOrders(data) {
    return request({
        url: '/app/orders-submit',
        method: 'post',
        data: data
    })
}
