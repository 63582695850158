<template>
  <div class="index">
    <van-nav-bar title="商品详情" left-arrow @click-left="back"/>
    <div class="main">
      <div class="content">
        <img :src="IMAGE_BASE_URL + product.image" alt=""/>
        <div class="notice">官方授权保障，售后无忧</div>
        <div class="info">
          <div class="info-title">{{ product.title }}</div>
          <div class="info-desc">
            {{ product.subtitle }}
          </div>
        </div>
        <div class="grid">
          <div class="item">
            <div class="num">{{ product.packageFlow }}</div>
            <div class="label">通用流量</div>
          </div>
          <div class="item">
            <div class="num">{{ product.duration }}</div>
            <div class="label">全国通话</div>
          </div>
          <div class="item">
            <div class="num">{{ product.postage }}</div>
            <div class="label">全国接听</div>
          </div>
        </div>
      </div>
      <div class="title">商品详情</div>
      <div class="details rich-text" v-html="product.detail"></div>
    </div>
    <div class="footer">
      <div class="btn" @click="showPopup">免费领取</div>
    </div>
    <van-popup v-model:show="show" position="bottom" round closeable>
      <div class="address-popup">
        <div class="tips">
          根据国家手机卡实名要求，请如实填写以下信息，以便我们及时为您送达。
        </div>
        <div class="input-title">收货人信息</div>
        <div class="input-row">
          <div class="input-label">姓名</div>
          <div class="input-value">
            <input
                v-model="orderInfo.name"
                type="text"
                placeholder="请输入办理人姓名(已加密)"
            />
          </div>
        </div>
        <div class="input-row">
          <div class="input-label">身份证号码</div>
          <div class="input-value">
            <input
                v-model="orderInfo.identificationNumber"
                type="text"
                placeholder="请输入办理人身份证号码(已加密)"
            />
          </div>
        </div>
        <div class="input-row">
          <div class="input-label">联系号码</div>
          <div class="input-value">
            <input
                v-model="orderInfo.phone"
                type="text"
                placeholder="请输入收件人电话(已加密)"
            />
          </div>
        </div>
        <div class="input-title">收货地址</div>
        <div class="input-row">
          <div class="input-label">收货地址</div>
          <div class="input-value">
            <input
                v-model="cityInfo"
                type="text"
                placeholder="请选择省份/地市/县区(已加密)"
                @click="showPicker = true"
            />
          </div>
        </div>
        <div class="input-row">
          <div class="input-label">详细地址</div>
          <div class="input-value">
            <input
                v-model="orderInfo.address"
                type="text"
                placeholder="如街道、道路、小区、门牌号等"
            />
          </div>
        </div>
        <van-divider />
        <div class="user-agreement">
          <div class="left-check">
            <van-checkbox v-model="checked">请阅读以下协议并勾选：</van-checkbox>
          </div>
          <div class="agreement-list" style="color: blue; font-size: 14px; margin-left: 22px;">
            <span @click="checkAgreement(1)">《个人信息收集、使用同意函》</span> <span @click="checkAgreement(2)">《关于客户个人信息收集、使用及保护的公告》</span>
            <span @click="checkAgreement(3)">《办理电话号码开户法律风险及防范提示告知书》</span> <span @click="checkAgreement(4)">《中国电信用户入网服务协议及业务协议》</span>

          </div>
        </div>
        <div class="submit" @click="handleSubmit">立即领取</div>
      </div>
    </van-popup>
    <van-popup v-model:show="showPicker" round position="bottom" :style="{padding:'10px 20px'}">
      <van-area
          ref="areaOption"
          title="城市列表"
          v-model="areaCode"
          :area-list="areaList"
          @cancel="showPicker = false"
          @confirm="handleAreaConfirm"/>
    </van-popup>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useRouter, useRoute} from 'vue-router'
import {IMAGE_BASE_URL} from "@/const/base";
import {productDetail, submitOrders} from "@/api/product";
import {areaList} from "@vant/area-data";
import {showFailToast, showSuccessToast} from "vant";

const show = ref(false);
const route = useRoute();
const productId = ref(undefined);
const userId = ref(null);
let timeout = ref(null);
const checked = ref(true);


function showPopup() {
  show.value = !show.value;
}

const showPicker = ref(false);
// const columns = ref([])//地址
const router = useRouter();

const product = ref({})
const getDetail = (id) => {
  productDetail(id).then(res => {
    product.value = res.data;
    console.log(product.value);
  });
}
const back = () => {
  router.back();
}

const areaCode = ref(undefined);
const orderInfo = ref({
  name: undefined,
  identificationNumber: undefined,
  phone: undefined,
  province: undefined,
  city: undefined,
  district: undefined,
  areaCode: undefined,
  address: undefined,
  productId: null,
  userId: null,
})
const cityInfo = ref(undefined);
const handleAreaConfirm = (params) => {
  if (params.selectedOptions.length === 3) {
    const province = params.selectedOptions[0].text;
    const city = params.selectedOptions[1].text;
    const district = params.selectedOptions[2].text;
    orderInfo.value.province = province;
    orderInfo.value.city = city;
    orderInfo.value.district = district;
    cityInfo.value = province + " " + city + " " + district;
  }
  showPicker.value = false;
}

const handleSubmit = () => {
  if (!checked.value){
    showFailToast("请先阅读并勾选协议");
    return;
  }
  if (!validateIDCard(orderInfo.value.identificationNumber)) {
    showFailToast("请输入正确的证件号码");
    return;
  }
  if (!validatePhoneNumber(orderInfo.value.phone)) {
    showFailToast("请输入正确的手机号");
    return;
  }
  if (!cityInfo.value) {
    showFailToast("请选择城市信息");
    return;
  }
  if (!orderInfo.value.name || orderInfo.value.name === '') {
    showFailToast("请输入姓名");
    return;
  }
  if (!orderInfo.value.address || orderInfo.value.address === '') {
    showFailToast("请输入详细地址");
    return;
  }
  orderInfo.value.productId = productId.value;
  orderInfo.value.userId = parseInt(userId.value);
  debounceNew(function (){
    submitOrders(orderInfo.value).then(() => {
      showPopup();
      showSuccessToast("订单提交成功");
    }).catch(() => {
    })
  },500,false)
}

function validatePhoneNumber(phoneNumber) {
  // 中国大陆手机号正则，11位数字，以1开头，第二位通常是3-9
  const regex = /^1[3-9]\d{9}$/;
  return regex.test(phoneNumber);
}

function validateIDCard(idCard) {
  // 中国大陆身份证号码正则，15位或18位
  const regex = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;
  return regex.test(idCard);
}

function checkAgreement(select){
  router.push({
    path: '/agreement',
    query: {select}
  })
}

onMounted(() => {
  productId.value = route.query.productId;
  userId.value = route.query.userId || null;
  getDetail(productId.value);
})

// 防抖
function debounceNew(func, wait, immediate){
  // 清除定时器
  if (timeout.value !== null) clearTimeout(timeout);
  // 立即执行，此类情况一般用不到
  if (immediate) {
    var callNow = !timeout.value;
    timeout = setTimeout(function () {
      timeout = null;
    }, wait);
    if (callNow) typeof func === "function" && func();
  } else {
    // 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
    timeout = setTimeout(function () {
      typeof func === "function" && func();
    }, wait);
  }
}
</script>
<style scoped>
.rich-text >>> img {
  max-width: 100%;
}
</style>
<style lang="less" scoped>

.index {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9;

  .header {
    flex: 0 0 auto;
    width: 100%;
    background-color: #fff;
    color: #333;
    text-align: center;
    height: 50px;
    line-height: 50px;
    font-size: 17px;
    font-weight: bold;
  }

  .main {
    flex: 1 0 0;
    overflow: auto;

    .content {
      margin: 0 0 5px;
      padding: 0 0 20px;
      background-color: #fff;

      img {
        width: 100%;
      }

      .notice {
        margin: 10px 8px;
        padding: 5px 10px;
        font-size: 12px;
        color: #9b7f59;
        background: linear-gradient(to right, #ffe9bd, #fff);
      }

      .info {
        margin: 0 8px;
        padding-left: 10px;

        .inf-title {
          color: #333;
          margin: 0 0 5px;
          font-size: 14px;
        }

        .info-desc {
          color: #999;
          font-size: 13px;
          margin: 0 0 5px;
        }
      }

      .grid {
        margin: 18px 18px 0;
        background-color: #f8f8f8;
        display: flex;
        border-radius: 10px;
        padding: 10px 0;

        .item {
          flex: 1;
          padding: 0 5px;
          border-right: 1px #999 solid;
          text-align: center;

          &:last-child {
            border-right: none;
          }

          .num {
            color: #333;
            font-size: 16px;
            padding: 0 0 2px;
            font-weight: bold;
          }

          .label {
            color: #999;
            font-size: 14px;
          }
        }
      }
    }

    .title {
      padding: 5px 10px;
      background-color: white;
      font-size: 15px;
      color: #333;
      font-weight: bold;
      text-align: left;
      margin-bottom: 5px;
    }

    .details {
      background-color: #fff;
      padding: 5px 10px;
      box-sizing: border-box;
      text-align: center;
    }


  }

  .footer {
    flex: 0 0 auto;
    padding: 15px 10px;
    background-color: #fff;

    .btn {
      margin: 0 27px;
      font-size: 14px;
      color: #fff;
      height: 35px;
      line-height: 35px;
      text-align: center;
      border-radius: 15px;
      background: linear-gradient(to right, #fc7528, #fb5421);
    }
  }

  .address-popup {
    padding: 50px 20px 20px;
    text-align: left;

    .tips {
      color: #999;
      font-size: 12px;
      margin: 0 0 20px;
    }

    .input-title {
      position: relative;
      color: #333;
      font-size: 15px;
      font-weight: bold;
      padding: 0 10px;
      margin: 5px 0 10px;

      &::before {
        position: absolute;
        left: 0;
        content: "";
        width: 2px;
        height: 16px;
        top: 10%;
        background-color: #ff5800;
      }
    }

    .input-row {
      margin: 0 0 10px;
      padding: 0 10px;
      border: 1px #999 solid;
      border-radius: 10px;
      height: 45px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 15px;
      color: #333;

      .input-label {
        color: #333;
      }

      .input-value {
        flex: 1;
        padding-left: 10px;

        input {
          width: 100%;
          outline: none;
          border: 0;
        }
      }
    }

    .submit {
      margin: 20px 33px 0;
      font-size: 20px;
      color: #fff;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-radius: 20px;
      background: linear-gradient(to right, #fc7528, #fb5421);
    }
  }
}
</style>
