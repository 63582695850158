<template>
  <div class="index">
    <van-nav-bar title="搜索" left-arrow @click-left="back"/>
    <div class="search">
      <div class="icon"><van-icon name="search" size="20" /></div>
      <input type="text" placeholder="搜索想要的商品" v-model="searchName">
      <div class="button" @click="search">搜索</div>
    </div>
    <div class="main">
      <div class="item-box" v-for="(item,index) in list" :key="index">
        <div class="poster">
          <img :src="prefix + item.image" />
        </div>
        <div class="item-right">
          <div class="info">
            <div class="title">{{item.title}}</div>
            <div class="desc">{{item.subtitle}}</div>
          </div>
          <div class="button" @click="toReceive(item.productId)">
            <div class="btn">免费领取</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {selectProductList} from "@/api/product";
export default {
  name: "goodsList",
  data() {
    return {
      searchName: '',
      list:[],
      prefix: "https://admin.xhhx888.cn/app-api/",
    }
  },
  created(){
    this.getList();
  },
  methods: {
    back(){
      this.$router.back();
    },
    getList(){
      selectProductList(this.searchName).then(res => {
        this.list = res.data;
      })
    },
    search(){
      console.log(this.searchName)
      this.getList();
    },
    toReceive(productId){
      this.$router.push({
        path: '/details',
        query: {productId}
      })
    }
  },
};

</script>

<style lang="less" scoped>
.index{
  display: flex;
  flex-direction: column;
  height: 100vh;
  .search{
    flex: 0 0 auto;
    height: 32px;
    border-radius: 15px;
    border: 1px #2a82e4 solid;
    display: flex;
    align-items: center;
    margin: 0 15px 0;
    background-color: #fff;
    box-sizing: border-box;
    .icon{
      padding: 2px 10px 0;
      height: 32px;
      line-height: 32px;
    }
    input{
      flex: 1 0 0;
      border: none;
    }
    .button{
      flex: 0 0 auto;
      height: 32px;
      width: 64px;
      line-height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background: rgba(42, 130, 228, 1);
      border-radius: 15px;
      font-size: 15px;
    }
  }
  .main{
    flex: 1 0 0;
    overflow: auto;
    padding: 15px;
    box-sizing: border-box;
    .item-box{
      width: 100%;
      margin: 0 0 10px;
      border-radius: 5px;
      background-color: #fff;
      box-sizing: border-box;
      display: flex;
      .poster{
        flex: 0 0 auto;
        width: 140px;
        height: 140px;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .item-right{
        flex: 1 0 0;
        padding: 5px 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .info{
          .title{
            color: rgba(51, 51, 51, 1);
            font-size: 14px;
            line-height: 20px;
            padding: 0 0 8px;
          }
          .desc{
            color: rgba(166, 166, 166, 1);
            font-size: 12px;
            line-height: 18px;
          }
        }
        .button{
          display: flex;
          justify-content: flex-end;
          .btn {
            width: 70px;
            height: 25px;
            line-height: 25px;
            font-size: 10px;
            color: #fff;
            background-color: #2a82e4;
            text-align: center;
            border-radius: 5px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
::v-deep .van-nav-bar{
  background-color: #f2f4f7;
}
</style>